<template>
  <div v-bind:id="widget_id" class="card card-default">
    <div class="card-header">
      <h3 class="card-title">{{ widget_meta.title }}</h3>

      <div class="card-tools">
        <button type="button" class="btn btn-tool" data-card-widget="collapse">
          <i class="fas fa-minus"></i>
        </button>
      </div>
    </div>

    <div class="card-body tools">
      <div class="row">
        <button type="button" @click="save" class="btn bg-indigo">確定排序</button>
        <button type="button" @click="cancel" class="btn bg-gray">取消排序</button>
      </div>
    </div>

    <div class="card-body">
      <table class="table table-striped">
        <thead>
          <tr>
            <th style="width: 10px">
              &nbsp;
            </th>
            <template v-for="field in widget_meta.widget.properties.sort_fields" v-bind:key="field.label">
              <th>{{ field.label }}</th>
            </template>
          </tr>
        </thead>
        <tbody>
          <template v-for="(row, index) in value" v-bind:key="index">
            <ml-row v-bind:data="row" v-bind:properties="widget_meta.widget.properties" v-bind:id="index"></ml-row>
          </template>
        </tbody>
      </table>
    </div>
    <!-- /.card-body -->
    <div class="card-footer text-center">

    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import Row from "./Row.vue"
export default {
  components: {
    'ml-row': Row
  },
  props: ['widget'],
  data() {
    // 取得 widget 的代碼
    let code = this.widget.widget;
    // 取得 widget 的參數
    let params = this.widget.params;
    // 取得 widget 的元件資訊
    let current_widget_meta = this.$microlink.widgets[code];
    // 資料初始化
    let rows = {};
    // 參數的網址字串
    let parameters = '';

    // 清單組件，必須要有 list_data 來源
    if (current_widget_meta.widget.properties.events.list_data == undefined ||
      current_widget_meta.widget.properties.events.list_data == '') {
      console.log('尚未指定 list_data 來源');
    }
    else {

      if (params == undefined) {
        params = {};
      }

      // 排序資料需要全部顯示，因此不分頁，也將分頁的參數移除(註冊)
      delete params._c_;
      /*
      if (current_widget_meta.widget.properties.items_per_page != undefined) {
        params['_c_'] = current_widget_meta.widget.properties.items_per_page;
      }
      */

      if (current_widget_meta.widget.properties.sort_rule_fields != undefined) {
        for (let i = 0; i < current_widget_meta.widget.properties.sort_rule_fields.length; i++) {
          let variable = current_widget_meta.widget.properties.sort_rule_fields[i].field_variable;
          let rule = current_widget_meta.widget.properties.sort_rule_fields[i].rule;
          params['_s_[' + variable + ']'] = rule;
        }
      }

      // 如果有「資料領域」的設定，則加入到參數中
      if (current_widget_meta.widget.properties.datazone_setting != undefined &&
        current_widget_meta.widget.properties.datazone_setting.datazone != undefined &&
        current_widget_meta.widget.properties.datazone_setting.datazone != '') {
        params['dz'] = current_widget_meta.widget.properties.datazone_setting.datazone;
      }

      // 如果有麵包屑的標題
      let breadcrumb_title = '';
      if (params.breadcrumb_title != undefined) {
        breadcrumb_title = params.breadcrumb_title;
      }

      delete params.breadcrumb_title;

      this.$emit('widgetEvent', { 'event': 'save', 'type': 'sort', 'title': breadcrumb_title, 'code': code, 'params': params });

      parameters = this.params_to_string(params);

      // 組合 API 網址
      let api_url = this.$microlink.base_api_url + current_widget_meta.widget.properties.events.list_data + parameters;
      // 取得資料
      let response = this.$microlink.api(api_url);

      // 資料
      rows = response.data;

    }

    return {
      widget_id: 'sort-' + Math.random().toString().substring(2),
      widget_meta: current_widget_meta,
      value: rows,
      sortedItems: [],
      params: params
    }
  },
  mounted() {
    let $this = this;
    $('#' + this.widget_id + ' tbody').sortable({
      handle: ".sort-drop",
      update: function (event, ui) {
        event; ui;
        $this.sortedItems = $(this).sortable("toArray");
      }
    });
  },
  methods: {
    // 將 params 物件轉換成網址字串
    // 如果面沒有參數，則不加入 & 字元
    params_to_string: function (params) {
      let parameters = '';
      for (let pkey in params) {
        if (parameters != '') {
          parameters += '&';
        }
        parameters += `${pkey}=${params[pkey]}`;
      }

      if (parameters != '') {
        parameters = '?' + parameters;
      }

      return parameters;
    },

    /** 
     * 儲存 
     */
    save: function () {

      // 沒有進行任何拖曳動作，直接返回
      if (this.sortedItems.length == 0) {
        this.$emit('widgetEvent', { 'event': 'back', 'step': 1 });
        return;
      }

      // 組合 API 網址
      let parameters = this.params_to_string(this.params);
      let api_url = this.$microlink.base_api_url + this.widget_meta.widget.properties.events.sort_data + parameters;

      let $this = this;
      // 發送資料
      this.$microlink.api_post(api_url, { 'sequence': this.sortedItems }, true, function (response) {
        response;
        if ($this.widget_meta.widget.properties.list_fields == undefined) {
          console.log('設定表單，沒有上一層清單');
        }
        else {
          $this.$emit('widgetEvent', { 'event': 'back', 'step': 1 });
        }
      });

    },

    /**
     * 取消 / 回上一頁
     */
    cancel: function () {
      // 沒有清單的設定，因此判斷為設定表單
      if (this.widget_meta.widget.properties.list_fields == undefined) {
        console.log('設定表單，沒有上一層清單');
      }
      else {
        this.$emit('widgetEvent', { 'event': 'back', 'step': 1 });
      }
    }
  }
};
</script>

<style scoped>
div.tools button {
  margin: 0px 3px;
}
</style>