<template>
    <tr v-bind:id="this.id">
        <td><i class="fas fa-grip-vertical sort-drop"></i></td>
        <template v-for="field in this.fields" v-bind:key="field.id">
            <td>
                <component v-bind:is="field._component" v-bind:key="field.variable" v-bind:field="field"
                    v-bind:value="this.data[field.variable]" v-bind:message="message[field.variable]"></component>
            </td>
        </template>
    </tr>
</template>


<script>
export default {
    props: ['id', 'data', 'properties'],
    data() {
        let localFields = this.properties.sort_fields;
        let message = {};

        // 將欄位轉換成實體的元件
        for (let key in localFields) {
            localFields[key]._component =
                this.$microlink.components[localFields[key].component.type].component;
            message[localFields[key].variable] = '';
        }

        return {
            permissions: this.properties.permissions,
            fields: localFields,
            message: message,
            value: this.data,
            buttons: this.properties.button_fields,
            widget_properties: this.properties
        };
    },
    methods: {
    }
};
</script>

<style scoped>
.sort-drop {
    cursor: move;
}
</style>